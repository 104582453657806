@charset "UTF-8";
/**
	* Computes a CSS calc function that betweens a value from
	* A to B over viewport-width A to viewport-width B.
	* Requires a media query to cap the value at B.
	* vw 단위를 활용하여 미디어쿼리 분기의 중간 상태값을
	* 계산해주는 SASS 함수.
	* 간격이나 폰트사이즈에 적용하면 자연스러운
	* 반응형 사이즈 처리가 가능 함.
	* 참고사이트 http://dohoons.com/blog/1364
	*/
/**
	* Read the article: http://sassbreak.com/viewport-relative-headings-with-sass
	* px 를 vw 로 자동으로 바꿔 줌.
	* max-breakpoint 를 설정하면 미디어쿼리 브레이크 포인트가 됨.
	* 그래서 미디어쿼리 브레이크 포인트에 맞춰 px 가 vw 로 변환됨.
	* 100% 보다 더 크게 적용 하고 싶으면 return 부분을 수정하면됨.
	* 참고사이트 http://sassbreak.com/viewport-relative-headings-with-sass/
	*/
@media (max-width: 1023px) {
  /*! contents - mobile */
  [class*="web-"] {
    display: none !important; }
  .acnon .title-wrap .title span em {
    letter-spacing: -1px;
    color: #ff5b5b; }
  .acnon .title-wrap .sub-tit {
    font-family: "NotoSans-Medium"; }
  .acnon .title-wrap .sub-txt em {
    font-family: "NotoSans-Medium"; }
  .acnon .sec .mo-title {
    color: #ff5b5b; }
  .acnon .section01 {
    position: relative;
    padding-bottom: 22.66667vw;
    background: url(../../images/mo/brand/ac_sec01_bg.png) no-repeat center -4.66667vw;
    background-size: cover; }
    .acnon .section01 .sec01-cnt {
      margin-top: 12vw; }
      .acnon .section01 .sec01-cnt .circle-wrap {
        display: flex;
        width: 71.33333vw;
        padding-bottom: 20vw;
        margin: auto;
        justify-content: center;
        background: url(../../images/brand/ac_sec01_bg01.png) no-repeat center 4vw;
        background-size: contain; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item img {
          display: block;
          width: 13.33333vw;
          margin: auto; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item span {
          display: block;
          margin-top: 0.66667vw;
          color: #8a4d45;
          font-family: "NotoSans-Medium";
          font-size: 3.46667vw; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item + .circle-item {
          margin-left: 8vw; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item:nth-child(2) {
          margin-top: -4.66667vw; }
      .acnon .section01 .sec01-cnt .sec01-cnt02 {
        position: relative;
        text-align: center; }
        .acnon .section01 .sec01-cnt .sec01-cnt02 .photo-img {
          position: absolute;
          left: 0;
          top: -16%;
          width: 28vw; }
        .acnon .section01 .sec01-cnt .sec01-cnt02 .acnon-img {
          width: 78%; }
        .acnon .section01 .sec01-cnt .sec01-cnt02 .txt-img {
          position: absolute;
          right: 1%;
          top: -25%;
          width: 22.93333vw; }
      .acnon .section01 .sec01-cnt .sub-txt {
        max-width: 100%; }
        .acnon .section01 .sec01-cnt .sub-txt span {
          display: block;
          margin-bottom: 0;
          font-size: 3.73333vw;
          line-height: 1.5;
          font-family: "NotoSans-DemiLight"; }
          .acnon .section01 .sec01-cnt .sub-txt span em {
            font-family: "NotoSans-Medium"; }
  .acnon .section02 {
    background: url(../../images/mo/brand/ac_sec02_bg.png) no-repeat top right;
    background-size: cover; }
    .acnon .section02 .title span:last-child {
      margin-top: 4vw;
      font-size: 4vw; }
    .acnon .section02 .feature-cnt {
      margin-top: 15.33333vw; }
      .acnon .section02 .feature-cnt .sec02-circle {
        display: flex;
        width: 77.86667vw;
        height: 40vw;
        padding: 0 5.33333vw;
        margin: auto;
        align-items: center;
        border-radius: 20vw;
        box-sizing: border-box; }
        .acnon .section02 .feature-cnt .sec02-circle img {
          width: 17.6vw;
          height: 17.6vw; }
        .acnon .section02 .feature-cnt .sec02-circle .txt-box {
          margin-left: 4vw;
          text-align: left; }
          .acnon .section02 .feature-cnt .sec02-circle .txt-box .tit {
            display: block;
            margin-bottom: 2.66667vw;
            font-size: 4.26667vw;
            font-family: "NotoSans-Medium"; }
          .acnon .section02 .feature-cnt .sec02-circle .txt-box .txt {
            color: #762e2c;
            font-size: 3.46667vw;
            line-height: 1.5; }
        .acnon .section02 .feature-cnt .sec02-circle:nth-child(1) {
          background-color: #ff5b5b; }
          .acnon .section02 .feature-cnt .sec02-circle:nth-child(1) .tit {
            color: #fff; }
          .acnon .section02 .feature-cnt .sec02-circle:nth-child(1) .txt {
            color: rgba(255, 255, 255, 0.7); }
        .acnon .section02 .feature-cnt .sec02-circle:nth-child(2) {
          background-color: #ffaba4; }
          .acnon .section02 .feature-cnt .sec02-circle:nth-child(2) .tit {
            color: #ad0806; }
        .acnon .section02 .feature-cnt .sec02-circle:nth-child(3) {
          background-color: #ffdfdc; }
          .acnon .section02 .feature-cnt .sec02-circle:nth-child(3) .tit {
            color: #ff5b5b; }
        .acnon .section02 .feature-cnt .sec02-circle + .sec02-circle {
          margin-top: 2.66667vw; }
      .acnon .section02 .feature-cnt .img-circle {
        position: relative;
        top: -28px;
        width: 70%;
        margin: auto; }
        .acnon .section02 .feature-cnt .img-circle img {
          width: 100%; }
  .acnon .section05 .media-cnt .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ff5b5b; }
  .product-info .title-wrap .title span em {
    color: #ff5b5b; }
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .active-box {
    opacity: 1;
    background-color: #ff5b5b; }
  .product-info .section02 .product-detail-wrap .title a {
    background-color: #ff5b5b; }
  .product-info .section03 {
    padding-bottom: 13.33333vw; }
    .product-info .section03 .swiper-pagination .swiper-pagination-bullet-active {
      background-color: #ff5b5b; }
  .acnon-qna .title-wrap .title span em {
    color: #ff5b5b; }
  .acnon-qna .section04 {
    background-color: #f1f5f8; }
    .acnon-qna .section04 .title-wrap .title {
      margin-bottom: 8.93333vw; }
    .acnon-qna .section04 .ui-accordion .accordion-item .btn .ico-q {
      color: #ff5b5b; }
    .acnon-qna .section04 .ui-accordion .accordion-item .detail p {
      white-space: inherit; }
      .acnon-qna .section04 .ui-accordion .accordion-item .detail p + p {
        margin-top: 3.33333vw; }
    .acnon-qna .section04 .ui-accordion .accordion-item .detail .tit {
      width: 100%;
      margin: 0 0 1.33333vw 0;
      font-family: "NotoSans-Medium";
      font-size: 3.73333vw;
      color: #333; }
      .acnon-qna .section04 .ui-accordion .accordion-item .detail .tit.point {
        color: #ff5b5b; } }

@media screen and (max-width: 1023px) and (min-width: 751px) and (max-width: 1023px) {
  .acnon .section01 {
    padding-bottom: 170px;
    background: url(../../images/mo/brand/ac_sec01_bg.png) no-repeat center -35px;
    background-size: cover; }
    .acnon .section01 .sec01-cnt {
      margin-top: 90px; }
      .acnon .section01 .sec01-cnt .circle-wrap {
        width: 535px;
        padding-bottom: 150px;
        background: url(../../images/brand/ac_sec01_bg01.png) no-repeat center 30px;
        background-size: contain; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item img {
          width: 100px; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item span {
          margin-top: 5px;
          font-size: 26px; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item + .circle-item {
          margin-left: 60px; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item:nth-child(2) {
          margin-top: -35px; }
      .acnon .section01 .sec01-cnt .sec01-cnt02 {
        width: 670px;
        margin: auto; }
        .acnon .section01 .sec01-cnt .sec01-cnt02 .photo-img {
          width: 210px; }
        .acnon .section01 .sec01-cnt .sec01-cnt02 .txt-img {
          width: 172px; }
      .acnon .section01 .sec01-cnt .sub-txt span {
        font-size: 28px; }
  .acnon .section02 .title span:last-child {
    margin-top: 30px;
    font-size: 30px; }
  .acnon .section02 .feature-cnt {
    margin-top: 115px; }
    .acnon .section02 .feature-cnt .sec02-circle {
      width: 584px;
      height: 300px;
      padding: 0 40px;
      border-radius: 150px; }
      .acnon .section02 .feature-cnt .sec02-circle img {
        width: 132px;
        height: 132px; }
      .acnon .section02 .feature-cnt .sec02-circle .txt-box {
        margin-left: 30px; }
        .acnon .section02 .feature-cnt .sec02-circle .txt-box .tit {
          margin-bottom: 20px;
          font-size: 32px; }
        .acnon .section02 .feature-cnt .sec02-circle .txt-box .txt {
          font-size: 26px; }
      .acnon .section02 .feature-cnt .sec02-circle + .sec02-circle {
        margin-top: 20px; }
    .acnon .section02 .feature-cnt .img-circle {
      top: -40px; }
  .product-info .section03 {
    padding-bottom: 100px; }
  .acnon-qna .title-wrap {
    padding-bottom: 160px 0 60px; }
  .acnon-qna .section04 {
    padding-bottom: 160px; }
    .acnon-qna .section04 .title-wrap .title {
      margin-bottom: 67px; }
    .acnon-qna .section04 .ui-accordion .accordion-item .detail p + p {
      margin-top: 25px; }
    .acnon-qna .section04 .ui-accordion .accordion-item .detail .tit {
      margin-bottom: 10px;
      font-size: 28px; } }

@media screen and (max-width: 1023px) and (-ms-high-contrast: active), (max-width: 1023px) and (-ms-high-contrast: none) {
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt {
    max-height: inherit !important; }
  .noscarna-qna .section02 .qna-sec02-cnt {
    background-size: contain; } }

@media (max-width: 1023px) {
  /* 다국어 */
  /* 영문 */
  :lang(en) #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
    font-size: 3.06667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 3.46667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 31.33333vw;
    left: 9.06667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 31.33333vw;
    right: 14.26667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 33.33333vw; }
  :lang(en) .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
    font-size: 2.53333vw; }
  :lang(en) .noscarna-qna .section02 .btm-txt .txt-box .txt {
    font-size: 4.13333vw; }
  :lang(en) .product-info .section02 .precautions .btn-download {
    width: 52vw; }
  :lang(en) .product-info .section02 .product-detail-wrap .title a {
    padding: 2.93333vw 4vw; }
  :lang(en) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -1.06667vw; }
  :lang(en) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -1.06667vw;
    left: 0; }
  :lang(en) .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
    line-height: 1.55; } }

@media screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 750px) {
  :lang(en) #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
    font-size: 23px; }
  :lang(en) .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
    padding: 29px 30px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 26px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 235px;
    left: 68px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 235px;
    right: 107px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 250px; }
  :lang(en) .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
    font-size: 19px; }
  :lang(en) .noscarna-qna .section02 .btm-txt .txt-box .txt {
    font-size: 31px; }
  :lang(en) .product-info .section02 .precautions .btn-download {
    width: 390px; }
  :lang(en) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -8px; }
  :lang(en) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -8px;
    left: 0; } }

@media (max-width: 1023px) {
  /* 일문 */
  :lang(ja) .ui-accordion .accordion-item .btn .txt {
    font-family: "NotoSans-Medium"; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(ko) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(en) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(ja) .brand .title-wrap .sub-txt span {
    word-break: break-all; }
  :lang(ja) .brand .title-wrap .sub-txt {
    max-width: 810px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 32.53333vw;
    left: 7.06667vw; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 32.53333vw;
    right: 12vw; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 31.06667vw;
    bottom: 7.06667vw; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 3.06667vw; }
  :lang(ja) .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle .txt {
    font-size: 3.73333vw; }
  :lang(ja) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -1.86667vw; }
  :lang(ja) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -1.33333vw;
    left: 0; } }

@media screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 750px) {
  :lang(ja) .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
    font-size: 36px; }
  :lang(ja) .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
    padding: 31px 30px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 23px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 244px;
    left: 53px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 244px;
    right: 90px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 233px;
    bottom: 53px; }
  :lang(ja) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -14px; }
  :lang(ja) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -10px;
    left: 0; } }

@media (max-width: 1023px) {
  /* 중문 */
  :lang(zh) .ui-accordion .accordion-item .btn .txt {
    font-family: "NotoSans-Medium"; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(ko) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(en) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    left: 16vw; }
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 37.33333vw;
    bottom: 4.93333vw; }
  :lang(zh) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -2vw; }
  :lang(zh) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -1.2vw;
    left: 0; } }

@media screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 750px) {
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    left: 120px; }
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 280px;
    bottom: 37px; }
  :lang(zh) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -15px; }
  :lang(zh) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -9px;
    left: 0; } }

@media (min-width: 1024px) {
  /*! contents - pc */
  .acnon .fix-indi button:hover {
    color: #ff5b5b; }
    .acnon .fix-indi button:hover:after {
      background-color: #ff5b5b; }
  .acnon .fix-indi button.on {
    color: #ff5b5b; }
    .acnon .fix-indi button.on:after {
      background-color: #ff5b5b; }
  .acnon .fix-indi.change-other-color button:hover {
    color: #ff5b5b; }
    .acnon .fix-indi.change-other-color button:hover:after {
      background-color: #ff5b5b; }
  .acnon .fix-indi.change-other-color button.on {
    color: #ff5b5b; }
    .acnon .fix-indi.change-other-color button.on:after {
      background-color: #ff5b5b; }
  .acnon .sec .title-wrap .title {
    margin-bottom: 17px; }
    .acnon .sec .title-wrap .title span {
      display: block;
      letter-spacing: -1px;
      font-family: "NotoSans-Light";
      font-size: 52px;
      line-height: 1.3; }
      .acnon .sec .title-wrap .title span em {
        letter-spacing: -1px;
        font-family: "NotoSans-Bold";
        font-size: 52px;
        line-height: 1.3;
        color: #ff5b5b; }
  .acnon .sec .title-wrap .sub-tit {
    max-width: 670px;
    margin: auto;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 30px; }
  .acnon .sec .title-wrap .tit {
    margin: 20px 0 38px; }
  .acnon .sec .title-wrap .sub-txt {
    max-width: 100%; }
    .acnon .sec .title-wrap .sub-txt span {
      display: block;
      margin-bottom: 0;
      font-size: 22px;
      line-height: 1.5;
      font-family: "NotoSans-DemiLight"; }
      .acnon .sec .title-wrap .sub-txt span em {
        font-family: "NotoSans-Medium"; }
      .acnon .sec .title-wrap .sub-txt span:last-child {
        margin-top: 10px;
        font-size: 22px;
        line-height: 1.5; }
  .acnon .section01 {
    position: relative;
    background: url(../../images/brand/ac_sec01_bg.png) no-repeat center;
    background-size: cover; }
    .acnon .section01 .sec01-cnt {
      margin-top: 80px; }
      .acnon .section01 .sec01-cnt .circle-wrap {
        display: flex;
        width: 761px;
        padding-bottom: 60px;
        margin: auto;
        justify-content: center;
        background: url(../../images/brand/ac_sec01_bg01.png) no-repeat center 35px; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item img {
          display: block; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item .txt-box {
          margin-top: 25px; }
          .acnon .section01 .sec01-cnt .circle-wrap .circle-item .txt-box .ref-txt {
            top: -15px;
            left: -2px; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item span {
          display: block;
          margin-top: 0.26042vw;
          color: #8a4d45;
          font-family: "NotoSans-Medium"; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item + .circle-item {
          margin-left: 64px; }
        .acnon .section01 .sec01-cnt .circle-wrap .circle-item:nth-child(2) {
          margin-top: -35px; }
      .acnon .section01 .sec01-cnt .sec01-cnt02 {
        position: relative;
        text-align: center; }
        .acnon .section01 .sec01-cnt .sec01-cnt02 .photo-img {
          position: absolute;
          left: 7%; }
        .acnon .section01 .sec01-cnt .sec01-cnt02 .txt-img {
          position: absolute;
          right: 14%; }
      .acnon .section01 .sec01-cnt .sub-txt {
        max-width: 100%; }
        .acnon .section01 .sec01-cnt .sub-txt span {
          display: block;
          margin-bottom: 0;
          font-size: 22px;
          line-height: 1.5;
          font-family: "NotoSans-DemiLight"; }
          .acnon .section01 .sec01-cnt .sub-txt span em {
            font-family: "NotoSans-Medium"; }
  .acnon .section02 {
    position: relative;
    padding: 100px 0 70px;
    background: url(../../images/brand/ac_sec02_bg.png) no-repeat top right;
    background-size: cover; }
    .acnon .section02 .feature-cnt {
      width: 1034px;
      margin: auto; }
      .acnon .section02 .feature-cnt .sec02-circle-wrap {
        display: flex;
        margin-top: 70px;
        justify-content: center; }
        .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle {
          position: relative;
          width: 365px;
          height: 365px;
          padding-top: 30px;
          border-radius: 100%;
          box-sizing: border-box; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle img {
            width: 90px; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt-box {
            margin-top: 25px; }
            .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt-box .ref-txt {
              top: -15px;
              left: -2px; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .tit {
            display: block;
            margin-bottom: 20px;
            font-family: "NotoSans-Bold";
            font-size: 24px; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
            color: #762e2c;
            font-size: 20px; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle + .sec02-circle {
            margin-left: -31px; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(1) {
            background-color: rgba(254, 57, 57, 0.8);
            z-index: 2; }
            .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(1) .tit {
              color: #fff; }
            .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(1) .txt {
              color: #fff; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(2) {
            background-color: rgba(255, 132, 122, 0.8);
            z-index: 1; }
            .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(2) .tit {
              color: #ad0806; }
          .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(3) {
            background-color: rgba(255, 210, 206, 0.8); }
            .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(3) .tit {
              color: #ff5b5b; }
      .acnon .section02 .feature-cnt .img-circle {
        position: relative;
        top: -67px;
        z-index: 10; }
  .acnon .section05 .media-cnt .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ff5b5b; }
  .product-info .title-wrap .title span em {
    color: #ff5b5b; }
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .active-box {
    opacity: 1;
    background-color: #ff5b5b; }
  .product-info .section02 .product-detail-wrap .title a {
    background-color: #ff5b5b; }
  .acnon-qna .title-wrap {
    padding: 0 0 0.67708vw; }
    .acnon-qna .title-wrap .title span em {
      color: #ff5b5b; }
    .acnon-qna .title-wrap .sub-txt {
      max-width: 100%; }
  .acnon-qna .section04 {
    min-height: calc(100vh - 498px);
    background-color: #f1f5f8; }
    .acnon-qna .section04 .title-wrap .title {
      margin-bottom: 3.64583vw; }
    .acnon-qna .section04 .ui-accordion .accordion-item .btn .ico-q {
      color: #ff5b5b; }
    .acnon-qna .section04 .ui-accordion .accordion-item .detail p {
      white-space: inherit; }
    .acnon-qna .section04 .ui-accordion .accordion-item .detail .tit {
      margin: 0 0 10px 0;
      font-family: "NotoSans-Medium";
      font-size: 18px;
      color: #333; }
      .acnon-qna .section04 .ui-accordion .accordion-item .detail .tit.point {
        color: #ff5b5b; } }

@media screen and (min-width: 1024px) and (min-width: 1024px) and (max-width: 1200px) {
  .acnon .section01 .sec01-cnt .sec01-cnt02 .photo-img {
    left: 0; }
  .acnon .section01 .sec01-cnt .sec01-cnt02 .txt-img {
    right: 5%; }
  .acnon .section02 .feature-cnt {
    width: 100%; }
    .acnon .section02 .feature-cnt .sec02-circle-wrap .sec02-circle {
      width: 348px;
      height: 348px; } }
