@charset "utf-8";

/*! contents - mobile */
[class*="web-"] {
  display: none !important;
}
// 브랜드 스토리
.acnon {
  .title-wrap {
    .title {
      span {
        em {
          letter-spacing: -1px;
          color: #ff5b5b;
        }
      }
    }
    .sub-tit {
      font-family: $NotoSansM;
    }
    .sub-txt {
      em {
        font-family: $NotoSansM;
      }
    }
  }
  .sec {
    .mo-title {
      color: #ff5b5b;
    }
  }
  
  .section01 {
    position: relative;
    padding-bottom: get-vw-mo(170px);
    background: url(../../images/mo/brand/ac_sec01_bg.png) no-repeat center get-vw-mo(-35px);
    background-size: cover;
    .sec01-cnt {
      margin-top: get-vw-mo(90px);
      .circle-wrap {
        display: flex;
        width: get-vw-mo(535px);
        padding-bottom: get-vw-mo(150px);
        margin: auto;
        justify-content:center;
        background:url(../../images/brand/ac_sec01_bg01.png) no-repeat center get-vw-mo(30px);
        background-size: contain;
        .circle-item {
          img {
            display: block;
            width: get-vw-mo(100px);
            margin: auto;
          }
          span {
            display: block;
            margin-top: get-vw-mo(5px);
            color: #8a4d45;
            font-family: $NotoSansM;
            font-size: get-vw-mo(26px);
          }
          & + .circle-item {
            margin-left: get-vw-mo(60px);
          }
          &:nth-child(2) {
            margin-top: get-vw-mo(-35px);
          }
        }
      }
      .sec01-cnt02 {
        position: relative;
        text-align: center;
        .photo-img {
          position: absolute;
          left: 0;
          top: -16%;
          width: get-vw-mo(210px);
        }
        .acnon-img {
          width: 78%;
        }
        .txt-img {
          position: absolute;
          right: 1%;
          top: -25%;
          width: get-vw-mo(172px);
        }
      }
      .sub-txt {
        max-width: 100%;
        span {
          display: block;
          margin-bottom: 0;
          font-size: get-vw-mo(28px);
          line-height: 1.5;
          font-family: $NotoSansDL;
          em {
            font-family: $NotoSansM;
          }
        }
      }
    }
  }
  .section02 {
    background: url(../../images/mo/brand/ac_sec02_bg.png) no-repeat top right;
    background-size: cover;
    .title {
      span:last-child {
        margin-top: get-vw-mo(30px);
        font-size: get-vw-mo(30px);
      }
    }
    .feature-cnt {
      margin-top: get-vw-mo(115px);
      .sec02-circle {
        display: flex;
        width: get-vw-mo(584px);
        height: get-vw-mo(300px);
        padding: 0 get-vw-mo(40px);
        margin: auto;
        align-items: center;
        
        border-radius: get-vw-mo(150px);
        box-sizing: border-box;
        img {
          width: get-vw-mo(132px);
          height: get-vw-mo(132px);
        }
        .txt-box {
          margin-left: get-vw-mo(30px);
          text-align: left;
          .tit {
            display: block;
            margin-bottom: get-vw-mo(20px);
            font-size: get-vw-mo(32px);
            font-family: $NotoSansM;
          }
          .txt {
            color: #762e2c;
            font-size: get-vw-mo(26px);
            line-height: 1.5;
          }
        }
        &:nth-child(1) {
          background-color: #ff5b5b;
          .tit {
            color: #fff;
          }
          .txt {
            color: rgba(255,255,255,0.7);
          }
        }
        &:nth-child(2) {
          background-color: #ffaba4;
          .tit {
            color: #ad0806;
          }
        }
        &:nth-child(3) {
          background-color: #ffdfdc;
          .tit {
            color: #ff5b5b;
          }
        }
        & + .sec02-circle {
          margin-top: get-vw-mo(20px);
        }
      }
      .img-circle {
        position: relative;
        top: -28px;
        width: 70%;
        margin: auto;
        img {
          width: 100%;
        }
      }
    }
  }
  .section05 {
    .media-cnt {
      .swiper-pagination {
        .swiper-pagination-bullet-active {
          background-color: #ff5b5b;
        }
      }
    }
  }
}

//제품정보
.product-info {
  .title-wrap {
    .title {
      span {
        em {
          color: #ff5b5b;
        }
      }
    }
  }
  .section01 {
    .product-swiper {
      .swiper-wrapper {
        .swiper-slide.on {
          .active-box {
            opacity: 1;
            background-color: #ff5b5b;
          }
        }
      }
    }
  }
  .section02 {
    .product-detail-wrap {
      .title {
        a {
          background-color: #ff5b5b;
        }
      }
    }
  }
  .section03 {
    padding-bottom: get-vw-mo(100px);
    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background-color: #ff5b5b;
      }
    }
  }
}

// 궁금해요
.acnon-qna {
  .title-wrap {
    .title {
      span {
        em {
          color: #ff5b5b;
        }
      }
    }
  }
  .section04 {
    background-color: #f1f5f8;
    .title-wrap {
      .title {
        margin-bottom: get-vw-mo(67px);
      }
    }
    .ui-accordion {
      .accordion-item {
        .btn {
          .ico-q {
            color: #ff5b5b;
          }
        }
        .detail {
          p {
            white-space: inherit;
            & + p {
              margin-top: get-vw-mo(25px);
            }
          }
          .tit {
            width: 100%;
            margin: 0 0 get-vw-mo(10px) 0;
            font-family: $NotoSansM;
            font-size: get-vw-mo(28px);
            color:#333;
            &.point {
              color: #ff5b5b;
            }
          }
        }
      }
    }  
  }
}

// 태블릿
@media screen and (min-width: 751px) and (max-width: 1023px) {
  // 브랜드 스토리
  .acnon {
    .section01 {
      padding-bottom: 170px;
      background: url(../../images/mo/brand/ac_sec01_bg.png) no-repeat center -35px;
      background-size: cover;
      .sec01-cnt {
        margin-top: 90px;
        .circle-wrap {
          width: 535px;
          padding-bottom: 150px;
          background:url(../../images/brand/ac_sec01_bg01.png) no-repeat center 30px;
          background-size: contain;
          .circle-item {
            img {
              width: 100px;
            }
            span {
              margin-top: 5px;
              font-size: 26px;
            }
            & + .circle-item {
              margin-left: 60px;
            }
            &:nth-child(2) {
              margin-top: -35px;
            }
          }
        }
        .sec01-cnt02 {
          width: 670px;
          margin: auto;
          .photo-img {
            width: 210px;
          }
          .txt-img {
            width: 172px;
          }
        }
        .sub-txt {
          span {
            font-size: 28px;
          }
        }
      }
    }
    .section02 {
      .title {
        span:last-child {
          margin-top: 30px;
          font-size: 30px;
        }
      }
      .feature-cnt {
        margin-top: 115px;
        .sec02-circle {
          width: 584px;
          height: 300px;
          padding: 0 40px;
          border-radius: 150px;
          img {
            width: 132px;
            height: 132px;
          }
          .txt-box {
            margin-left: 30px;
            .tit {
              margin-bottom: 20px;
              font-size: 32px;
            }
            .txt {
              font-size: 26px;
            }
          }
          & + .sec02-circle {
            margin-top: 20px;
          }
        }
        .img-circle {
          top: -40px;
        }
      }
    }
  }
  
  // 제품정보
  .product-info .section03 {
    padding-bottom: 100px;
  }

  // 궁금해요
  .acnon-qna {
    .title-wrap {
      padding-bottom: 160px 0 60px;
    }
    .section04 {
      padding-bottom: 160px;
      .title-wrap {
        .title {
          margin-bottom: 67px;
        }
      }
      .ui-accordion {
        .accordion-item {
          .detail {
            p + p {
              margin-top: 25px;
            }
            .tit {
              margin-bottom: 10px;
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt {
    max-height: inherit !important;
  }
  .noscarna-qna .section02 .qna-sec02-cnt {
    background-size: contain;
  }

}



/* 다국어 */

/* 영문 */
:lang(en) {
    #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
      font-size:get-vw-mo(23px);
    }

    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
      font-size: get-vw-mo(26px);
    }

    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
      top: get-vw-mo(235px);
      left: get-vw-mo(68px);
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
      top: get-vw-mo(235px);
      right: get-vw-mo(107px);
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
      left: get-vw-mo(250px);
    }

    .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
      font-size: get-vw-mo(19px);
    }

    .noscarna-qna .section02 .btm-txt .txt-box .txt {
      font-size: get-vw-mo(31px);
    }

    .product-info .section02 .precautions .btn-download {
      width: get-vw-mo(390px);
    }

    .product-info .section02 .product-detail-wrap .title a {
      padding: get-vw-mo(22px) get-vw-mo(30px);
    }

    .noscarna .sec03-cnt-wrap p .ref-txt {
      top: get-vw-mo(-8px);
    }
    .noscarna .sec03-cnt-wrap span .ref-txt {
      top: get-vw-mo(-8px);
      left: 0;
    }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
      line-height: 1.55;
    }


    @media screen and (max-width: 1023px) and (min-width: 750px) {
      #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
        font-size:23px;
      }

      .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
        padding: 29px 30px;
      }

      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
        font-size: 26px;
      }
  
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
        top: 235px;
        left: 68px;
      }
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
        top: 235px;
        right: 107px;
      }
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
        left: 250px;
      }

      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
        font-size: 19px;
      }

      .noscarna-qna .section02 .btm-txt .txt-box .txt {
        font-size: 31px;
      }

      .product-info .section02 .precautions .btn-download {
        width: 390px;
      }

      .noscarna .sec03-cnt-wrap p .ref-txt {
        top: -8px;
      }
      .noscarna .sec03-cnt-wrap span .ref-txt {
        top: -8px;
        left: 0;
      }
      
    }
  
}

/* 일문 */
:lang(ja) {

  .ui-accordion .accordion-item .btn .txt {
    font-family: $NotoSansM;
  }

  .ui-accordion .accordion-item .detail p {
    @include notoSansDL();
  }

  .brand .title-wrap .sub-txt span {
    word-break: break-all;
  }

  .brand .title-wrap .sub-txt {
    max-width: 810px;
  }

  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1){
    top: get-vw-mo(244px);
    left: get-vw-mo(53px);
  }
  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2){
    top: get-vw-mo(244px);
    right: get-vw-mo(90px);
  }
  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: get-vw-mo(233px);
    bottom: get-vw-mo(53px);
  }

  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: get-vw-mo(23px);
  }

  .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle .txt {
    font-size: get-vw-mo(28px);
  }

  .noscarna .sec03-cnt-wrap p .ref-txt {
    top: get-vw-mo(-14px);
  }
  .noscarna .sec03-cnt-wrap span .ref-txt {
    top: get-vw-mo(-10px);
    left: 0;
  }

  

  @media screen and (max-width: 1023px) and (min-width: 750px) {
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
      font-size: 36px;
    }
  
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
      padding: 31px 30px;
    }
  
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
      font-size: 23px;
    }
  
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1){
      top: 244px;
      left: 53px;
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2){
      top: 244px;
      right: 90px;
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
      left: 233px;
      bottom: 53px;
    }

    .noscarna .sec03-cnt-wrap p .ref-txt {
      top: -14px;
    }
    .noscarna .sec03-cnt-wrap span .ref-txt {
      top: -10px;
      left: 0;
    }
  }

}

/* 중문 */
:lang(zh) {
  .ui-accordion .accordion-item .btn .txt {
    font-family: $NotoSansM;
  }

  .ui-accordion .accordion-item .detail p {
    @include notoSansDL();
  }

  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    left: get-vw-mo(120px);
  }
  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: get-vw-mo(280px);
    bottom: get-vw-mo(37px);
  }

  .noscarna .sec03-cnt-wrap p .ref-txt {
    top: get-vw-mo(-15px);
  }
  .noscarna .sec03-cnt-wrap span .ref-txt {
    top: get-vw-mo(-9px);
    left: 0;
  }

  @media screen and (max-width: 1023px) and (min-width: 750px) {
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
      left: 120px;
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
      left: 280px;
      bottom: 37px;
    }

    .noscarna .sec03-cnt-wrap p .ref-txt {
      top: -15px;
    }
    .noscarna .sec03-cnt-wrap span .ref-txt {
      top: -9px;
      left: 0;
    }
  }
}
