@charset "utf-8";


/*! contents - pc */

// 브랜드 스토리
.acnon {
  .fix-indi {
    button {
      &:hover {
        color: #ff5b5b;
        &:after {
          background-color: #ff5b5b;
        }
      }
      &.on {
        color: #ff5b5b;
        &:after {
          background-color: #ff5b5b;
        }
      }
    }
    &.change-other-color {
      button {
        &:hover {
          color: #ff5b5b;
          &:after {
            background-color: #ff5b5b;
          }
        }

        &.on {
          color: #ff5b5b;
          &:after {
            background-color: #ff5b5b;
          }
        }
      }
    }
  }
  .sec {
    .title-wrap {
      .title {
        margin-bottom: 17px;
        span {
          display: block;
          letter-spacing: -1px;
          font-family: $NotoSansL;
          font-size: $fontSizePc + 36;
          line-height: 1.3;
          em {
            letter-spacing: -1px;
            @include font($NotoSansB, $fontSizePc + 36, 1.3, #ff5b5b);
          }
        }
      }
      .sub-tit {
        max-width: 670px;
        margin: auto;
        @include text-overflow-multi(2);
        font-size: $fontSizePc + 14;
      }
      .tit {
        margin: 20px 0 38px;
      }
      .sub-txt {
        max-width: 100%;
        span {
          display: block;
          margin-bottom: 0;
          font-size: $fontSizePc + 6;
          line-height: 1.5;
          font-family: $NotoSansDL;
          em {
            font-family: $NotoSansM;
          }
          &:last-child {
            margin-top: 10px;
            font-size: $fontSizePc + 6;
            line-height: 1.5;
          }
        }
      }
    }
  }
  .section01 {
    position: relative;
    background: url(../../images/brand/ac_sec01_bg.png) no-repeat center;
    background-size: cover;
    .sec01-cnt {
      margin-top: 80px;
      .circle-wrap {
        display: flex;
        width: 761px;
        padding-bottom: 60px;
        margin: auto;
        justify-content:center;
        background:url(../../images/brand/ac_sec01_bg01.png) no-repeat center 35px;
        .circle-item {
          img {
            display: block;
          }
          .txt-box {
            .ref-txt {
              top: -15px;
              left: -2px;
            }
            margin-top: 25px;
          }
          span {
            display: block;
            margin-top: get-vw(5px);
            color: #8a4d45;
            font-family: $NotoSansM;
          }
          & + .circle-item {
            margin-left: 64px;
          }
          &:nth-child(2) {
            margin-top: -35px;
          }
        }
      }
      .sec01-cnt02 {
        position: relative;
        text-align: center;
        .photo-img {
          position: absolute;
          left: 7%;
        }
        .txt-img {
          position: absolute;
          right: 14%;
        }
      }
      .sub-txt {
        max-width: 100%;
        span {
          display: block;
          margin-bottom: 0;
          font-size: $fontSizePc + 6;
          line-height: 1.5;
          font-family: $NotoSansDL;
          em {
            font-family: $NotoSansM;
          }
        }
      }
    }
  }
  .section02 {
    position: relative;
    padding: 100px 0 70px;
    background: url(../../images/brand/ac_sec02_bg.png) no-repeat top right;
    background-size: cover;
    .feature-cnt {
      width: 1034px;
      margin: auto;
      .sec02-circle-wrap {
        display: flex;
        margin-top: 70px;
        justify-content: center;
        .sec02-circle {
          position: relative;
          width: 365px;
          height: 365px;
          padding-top: 30px;
          border-radius: 100%;
          box-sizing: border-box;
          img {
            width: 90px;
          }
          .txt-box {
            .ref-txt {
              top: -15px;
              left: -2px;
            }
            margin-top: 25px;
          }
          .tit {
            display: block;
            margin-bottom: 20px;
            font-family: $NotoSansB;
            font-size: $fontSizePc + 8;
          }
          .txt {
            color: #762e2c;
            font-size: $fontSizePc + 4;
          }
          & + .sec02-circle {
            margin-left: -31px;
          }
          &:nth-child(1) {
            background-color: rgba(254,57,57,0.8);
            z-index: 2;
            .tit {
              color: #fff;
            }
            .txt {
              color: #fff;
            }
          }
          &:nth-child(2) {
            background-color: rgba(255,132,122,0.8);
            z-index: 1;
            .tit {
              color: #ad0806;
            }
          }
          &:nth-child(3) {
            background-color: rgba(255,210,206,0.8);
            .tit {
              color: #ff5b5b;
            }
          }
        }
      }
      .img-circle {
        position: relative;
        top: -67px;
        z-index: 10;
      }
    }
  }
  .section05 {
    .media-cnt {
      .swiper-pagination {
        .swiper-pagination-bullet-active {
          background-color: #ff5b5b;
        }
      }
    }
  }
}

//제품정보
.product-info {
  .title-wrap {
    .title {
      span {
        em {
          color: #ff5b5b;
        }
      }
    }
  }
  .section01 {
    .product-swiper {
      .swiper-wrapper {
        .swiper-slide.on {
          .active-box {
            opacity: 1;
            background-color: #ff5b5b;
          }
        }
      }
    }
  }
  .section02 {
    .product-detail-wrap {
      .title {
        a {
          background-color: #ff5b5b;
        }
      }
    }
  }
}


// 궁금해요
.acnon-qna {
  .title-wrap {
    padding: 0 0 get-vw(13px);
    .title {
      span {
        em {
          color: #ff5b5b;
        }
      }
    }
    .sub-txt {
      max-width: 100%;
    }
  }
  .section04 {
    min-height: calc(100vh - 498px);
    background-color: #f1f5f8;
    .title-wrap {
      .title {
        margin-bottom: get-vw(70px);
      }
    }
    .ui-accordion {
      .accordion-item {
        .btn {
          .ico-q {
            color: #ff5b5b;
          }
        }
        .detail {
          p {
            white-space: inherit;
          }
          .tit {
            margin: 0 0 10px 0;
            font-family: $NotoSansM;
            font-size: $fontSizePc + 2;
            color:#333;
            &.point {
              color: #ff5b5b;
            }
            
          }
        }
      }
    }
  }
}

// wide
@media (min-width: 1920px) {

}

@media screen and (min-width: 1024px) and (max-width: 1340px) {

}

// 태블릿
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  //브랜드스토리
  .acnon {
    .section01 {
      .sec01-cnt {
        .sec01-cnt02 {
          .photo-img {
            left: 0;
          }
          .txt-img {
            right: 5%;
          }
        }
      }
    }
    .section02 {
      .feature-cnt {
        width: 100%;
        .sec02-circle-wrap {
          .sec02-circle {
            width: 348px;
            height: 348px;
          }
        }
      }
    }
  }

}






